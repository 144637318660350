import { Controller, useForm } from "react-hook-form";
import { Button, Col, Input, Label, ModalBody, ModalFooter, Row, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import FormHelper from "../../helpers/form";
import Api from "api/cost";
import Employees from "../../api/employees";
import Missions from "../../api/missions";

import Select from "react-select";

const Add = ({ fetchData, form, setForm }) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [workers, setWorkers] = useState([])
  const [services, setServices] = useState([])
  const data = form?.data;

  const submit = async (values) => {
    setLoader(true);
    try {
      const formData = new FormData();
      if (values.worker_id?.value !== null && values.worker_id?.value !== undefined) {
        formData.append("worker_id", values.worker_id.value);
      }
      if (values.service_id?.value !== null && values.service_id?.value !== undefined) {
        formData.append("service_id", values.service_id.value);
      }
      if (values.description !== null && values.description !== undefined) {
        formData.append("description", values.description);
      }
      if (values.quantity !== null && values.quantity !== undefined) {
        formData.append("quantity", values.quantity);
      }
      if (values.patient_price !== null && values.patient_price !== undefined) {
        formData.append("patient_price", values.patient_price);
      }
      if (values.technic_price !== null && values.technic_price !== undefined) {
        formData.append("technic_price", values.technic_price);
      }
      if (values.is_paid !== null && values.is_paid !== undefined) {
        formData.append("is_paid", values.is_paid);
      }

      if (data?.id) {
        await Api.update(formData, data.id);
      } else {
        await Api.add(formData);
      }

      fetchData();
      setForm({});
    } catch (e) {
      FormHelper.setApiErrors(e?.response?.data, setError);
    } finally {
      setLoader(false);
    }
  };

  const fetchServices = async () => {
    const { data } = await Missions.getSelect()
    setServices(data)

  }

  const fetchWorkers = async () => {
    const { data } = await Employees.getSelect()
    setWorkers(data)
  }

  useEffect(() => {
    fetchServices();
    fetchWorkers();
  }, []);

  useEffect(() => {
    if (data && workers?.length) {
      setValue('id', data.id)
      setValue('worker_id', {
        value: data?.worker_id,
        label: data?.worker,
      })
      setValue('service_id', {
        value: data?.service_id,
        label: data?.service,
      })
      setValue('patient_price', data?.patient_price)
      setValue('description', data.description)
      setValue('technic_price', data.technic_price)
      setValue('is_paid', data.is_paid)
      setValue('quantity', data.quantity)
    }
  }, [form, workers])
  return (
    <form onSubmit={handleSubmit(submit)}>
      <ModalBody>
        <Row>
          <Col sm={12}>
            <div className="mb-3">
              <Label for="worker_id">Texnik</Label>
              <Controller rules={{ required: true }} name="worker_id"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={workers}
                    placeholder=""
                    onChange={onChange}
                    value={value}
                    className={errors?.worker_id && 'is-invalid'}
                    name="worker_id"
                    id="worker_id" />
                )} />
              {FormHelper.generateFormFeedback(errors, 'worker_id')}
            </div>
            <div className="mb-3">
              <Label for="service_id">Xidmət</Label>
              <Controller
                name="service_id"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={services}
                    placeholder=""
                    className={`w-100 ${errors?.service_id && 'is-invalid'}`}
                    onChange={(selectedOption) => {
                      onChange(selectedOption);
                      setValue("patient_price", selectedOption.price);
                    }}
                    value={value}
                    name="service_id"
                    id="service_id"
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Label for="patient_price">Pasient qiyməti</Label>
              <Controller
                name="patient_price"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    id="patient_price"
                    placeholder="Pasient qiyməti"
                    value={value}
                    onChange={onChange}
                    disabled
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Label for="technic_price">Texniki qiymət</Label>
              <Controller
                name="technic_price"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    id="technic_price"
                    placeholder="Texniki qiymət"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Label for="quantity">Say</Label>
              <Controller
                name="quantity"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    id="quantity"
                    placeholder="Say"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Label for="is_paid">Ödəniş</Label>
              <Controller
                name="is_paid"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    id="is_paid"
                    placeholder="Ödəniş"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Label for="description">Qeyd</Label>
              <Controller
                name="description"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="text"
                    id="description"
                    placeholder="Qeyd"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>


          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          type="button"
          onClick={() => setForm({})}
          color="secondary"
        >
          Bağla
        </Button>
        <Button
          disabled={loader}
          type="submit"
          color="primary"
        >
          {loader ? <Spinner color="light" size="sm" /> : "Yadda saxla"}
        </Button>
      </ModalFooter>
    </form>
  );
};

export default Add;
