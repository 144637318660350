import { Controller, useForm } from "react-hook-form";
import { Button, Col, Input, Label, ModalBody, ModalFooter, Row, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import FormHelper from "../../helpers/form";
import Api from "api/cost-other";
import Employees from "../../api/employees";

import Select from "react-select";

const Add = ({ fetchData, form, setForm }) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [workers, setWorkers] = useState([])
  const data = form?.data;

  const submit = async (values) => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("worker_id", values.worker_id?.value);
      formData.append("description", values.description || "");
      formData.append("price", values.price);
      formData.append("type", values.type);
      if (data?.id) {
        await Api.update(formData, data.id);
      } else {
        await Api.add(formData);
      }

      fetchData();
      setForm({});
    } catch (e) {
      FormHelper.setApiErrors(e?.response?.data, setError);
    } finally {
      setLoader(false);
    }
  };

  const fetchWorkers = async () => {
    const { data } = await Employees.getSelect()
    setWorkers(data)
  }

  useEffect(() => {
    fetchWorkers();
  }, []);



  useEffect(() => {
    if (data && workers?.length) {
        setValue('id', data.id)
        setValue('worker_id', {
            value: data?.worker_id,
            label: data?.worker,
        })
        setValue('price', data?.price)
        setValue('description', data.description)
        setValue('type', data.type)
    }
}, [form, workers])
  return (
    <form onSubmit={handleSubmit(submit)}>
      <ModalBody>
        <Row>
          <Col sm={12}>
            <div className="mb-3">
              <Label for="worker_id">İşçi</Label>
              <Controller rules={{ required: true }} name="worker_id"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={workers}
                    placeholder=""
                    onChange={onChange}
                    value={value}
                    className={errors?.worker_id && 'is-invalid'}
                    name="worker_id"
                    id="worker_id" />
                )} />
              {FormHelper.generateFormFeedback(errors, 'worker_id')}
            </div>
            <div className="mb-3">
              <Label for="type">Növ</Label>
              <Controller  rules={{ required: true }}
                name="type"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="text"
                    id="type"
                    placeholder="Növ"
                    className={`w-100 ${errors?.type ? "is-invalid" : ""}`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {FormHelper.generateFormFeedback(errors, "type")}
            </div>
            <div className="mb-3">
              <Label for="price">Məbləğ</Label>
              <Controller 
                name="price"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    id="price"
                    placeholder="Məbləğ"
                    className={`w-100 ${errors?.price ? "is-invalid" : ""}`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {FormHelper.generateFormFeedback(errors, "price")}
            </div>
            <div className="mb-3">
              <Label for="description">Qeyd</Label>
              <Controller
                name="description"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="text"
                    id="description"
                    placeholder="Qeyd"
                    className={`w-100 ${errors?.description ? "is-invalid" : ""}`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {FormHelper.generateFormFeedback(errors, "description")}
            </div>


          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          type="button"
          onClick={() => setForm({})}
          color="secondary"
        >
          Bağla
        </Button>
        <Button
          disabled={loader}
          type="submit"
          color="primary"
        >
          {loader ? <Spinner color="light" size="sm" /> : "Yadda saxla"}
        </Button>
      </ModalFooter>
    </form>
  );
};

export default Add;
