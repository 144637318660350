import ReactPdf from "@react-pdf/renderer";
import moment from "moment/moment";
import Logo from '../../assets/images/sidebar/logo.png'
import RegularDejavu from './DejaVuSans.ttf'
import BoldDejavu from './DejaVuSans-Bold.ttf'
import ItalicDejavu from './DejaVuSerif-Italic.ttf'
import Can from "../../components/Common/Can";

const PDFTemplate = ({ item, inputValues, examinations, selectedExaminations }) => {
    let data = []
    if (examinations?.length) {
        data = [...examinations?.filter(e => selectedExaminations?.includes(e.id))]
    }
    if (item) {
        data = [item]
    }

    ReactPdf.Font.register({
        family: "DejaVu Sans",
        fonts: [
            { src: RegularDejavu, fontWeight: 'normal' },
            { src: BoldDejavu, fontWeight: 'bold' },
            { src: ItalicDejavu, fontStyle: 'italic' },
        ]
    });

    const styles = ReactPdf.StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            fontFamily: 'DejaVu Sans',
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16
        },
        header: {
            textAlign: 'center',
            fontWeight: 'bold'
        },
        bold: {
            fontWeight: 'bold'
        },
        section: {
            margin: 10,
            padding: 10,
        },
        textCenter: {
            textAlign: "center"
        },
        justifyBetween: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
            marginTop: 4,
            fontSize: 14,
        },
        divider: {
            height: 1,
            backgroundColor: '#000',
            width: '100%'
        },
        dividerText: {
            fontSize: 8,
            fontStyle: 'italic'
        },
        footer: {
            fontSize: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        table: {
            marginTop: 8,
            border: 1,
            borderColor: '#000',
        },
        tableHeaderRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#eff2ff',
        },
        tableHeaderCell: {
            paddingVertical: 2,
            textAlign: 'center',
            fontSize: 8,
            width: '24%',
            fontWeight: 'bold',
            borderRight: '1px solid black',
            borderBottom: '1px solid black'
        },
        tableRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableCell: {
            paddingVertical: 2,
            textAlign: 'center',
            fontSize: 7,
            width: '24%',
            borderRight: '1px solid black',
        }
    });

    return (
        <ReactPdf.BlobProvider document={<ReactPdf.Document>
            <ReactPdf.Page size="A4" style={styles.page}>
                <ReactPdf.View style={styles.section}>
                    <ReactPdf.Text style={styles.header}>
                        <ReactPdf.Image style={{
                            width: 200,
                            height: 30,
                            marginLeft: 80
                        }} src={Logo} />
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.footer}>
                    <ReactPdf.Text style={styles.dividerText}>
                        Bakı şəh, Ruhulla Axundov 8. {'\n'} Tel:+994 55 217 20 00.
                    </ReactPdf.Text>
                    <ReactPdf.Text style={styles.dividerText}>
                        {moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.divider} />
                <ReactPdf.View style={{ flexDirection: 'column', fontSize: 12 }}>
                    <ReactPdf.Text>
                        <ReactPdf.Text style={{ fontWeight: 'bold' }}>
                            Pasient adı:{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{ fontStyle: 'italic' }}>
                            {data?.[0]?.patient}
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                    <ReactPdf.Text>
                        <ReactPdf.Text style={{ fontWeight: 'bold' }}>
                            Doğum
                            tarixi:{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{ fontStyle: 'italic' }}>
                            {data?.[0]?.patient_birth} ({data?.[0]?.patient_fin_code})
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                    <ReactPdf.Text style={{ marginTop: 2 }}>
                        <ReactPdf.Text style={{ fontWeight: 'bold' }}>
                            Tel:{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{ fontStyle: 'italic' }}>
                            {data?.[0]?.patient_mobile_phone}
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.table}>
                    <ReactPdf.View style={styles.tableHeaderRow}>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { width: '4%' }]}>№</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Xidmət</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Diş</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Çənə</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Otaq</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { width: 100 }]}>Qiymət</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { width: 100 }]}>Endirim faizi</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { width: 100 }]}>Endirim rəqəmi</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { width: 100 }]}>Yekun qiy.</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { width: 100 }]}>Ödəniş</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { width: 80 }]}>Borc</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Göndərən</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, { borderRight: 'none' }]}>Həkim</ReactPdf.Text>
                    </ReactPdf.View>
                    {data.map((patient, index) => (
                        <ReactPdf.View
                            style={[styles.tableRow, { borderBottom: `${index + 1 === data.length ? 'none' : '1px solid #000'}` }]}
                            key={patient?.id}>
                            <ReactPdf.Text style={[styles.tableCell, { width: '4%' }]}>{index + 1}</ReactPdf.Text>
                            <ReactPdf.Text style={styles.tableCell}>{patient?.service}</ReactPdf.Text>
                            <ReactPdf.Text style={styles.tableCell}>{patient?.tooth_id}</ReactPdf.Text>
                            <ReactPdf.Text style={styles.tableCell}>{patient?.jaw_type}</ReactPdf.Text>
                            <ReactPdf.Text style={styles.tableCell}>{patient?.room}</ReactPdf.Text>
                            <ReactPdf.Text style={[styles.tableCell, { width: 100 }]}>{patient?.price}</ReactPdf.Text>
                            <ReactPdf.Text style={[styles.tableCell, { width: 100 }]}>{patient?.discount} %</ReactPdf.Text>
                            <ReactPdf.Text style={[styles.tableCell, { width: 100 }]}>{patient?.discount_number} </ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, { width: 100 }]}>{patient?.final_price}</ReactPdf.Text>
                            <ReactPdf.Text style={[styles.tableCell, { width: 100 }]}>{patient?.payment}</ReactPdf.Text>
                            <ReactPdf.Text style={[styles.tableCell, { width: 80 }]}>{patient?.debt}</ReactPdf.Text>
                            <ReactPdf.Text style={styles.tableCell}>{patient?.worker}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, { borderRight: 'none' }]}>{patient?.doctor}</ReactPdf.Text>
                        </ReactPdf.View>
                    ))}
                </ReactPdf.View>
                <ReactPdf.View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginTop: 5,
                        fontSize: 10
                    }}>
                    {data?.length && (
                        <ReactPdf.Text>
                            Məbləğ :{' '}
                            <ReactPdf.Text style={{ color: 'orange' }}>
                                {data?.reduce((acc, val) => {
                                    return acc + (val?.price)
                                }, 0).toFixed(2)}{' '}AZN
                            </ReactPdf.Text>
                        </ReactPdf.Text>
                    )}
                </ReactPdf.View>
                <ReactPdf.View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginTop: 5,
                        fontSize: 10
                    }}>
                    {data?.length && (
                        <ReactPdf.Text>
                            Endirim məbləği :{' '}
                            <ReactPdf.Text style={{ color: 'orange' }}>
                                {data?.reduce((acc, val) => {
                                    return acc + (val?.price - val?.final_price)
                                }, 0).toFixed(2)}{' '}AZN
                            </ReactPdf.Text>
                        </ReactPdf.Text>
                    )}
                </ReactPdf.View>
                <ReactPdf.View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginTop: 5,
                        fontSize: 10
                    }}>
                    {data?.length && (
                        <ReactPdf.Text>
                            Cəm:{' '}
                            <ReactPdf.Text>
                                {data?.reduce((acc, val) => {
                                    return acc + val?.final_price
                                }, 0).toFixed(2)}{' '}AZN
                            </ReactPdf.Text>
                        </ReactPdf.Text>
                    )}
                </ReactPdf.View>
                <ReactPdf.View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginTop: 5,
                        fontSize: 10
                    }}>
                    {data?.length && (
                        <ReactPdf.Text>
                            Cəm ödəniş:{' '}
                            <ReactPdf.Text style={{ color: 'green' }}>
                                {data?.reduce((acc, val) => {
                                    return acc + val?.payment
                                }, 0).toFixed(2)}{' '}AZN
                            </ReactPdf.Text>
                        </ReactPdf.Text>
                    )}
                </ReactPdf.View>
                <ReactPdf.View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginTop: 5,
                        fontSize: 10
                    }}>
                    {data?.length && (
                        <ReactPdf.Text>
                            Nağd ödəniş:{' '}
                            <ReactPdf.Text style={{ color: 'green' }}>
                                {data?.reduce((acc, val) => {
                                    return acc + val?.payment_cash
                                }, 0).toFixed(2)}{' '}AZN
                            </ReactPdf.Text>
                        </ReactPdf.Text>
                    )}
                </ReactPdf.View>
                <ReactPdf.View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginTop: 5,
                        fontSize: 10
                    }}>
                    {data?.length && (
                        <ReactPdf.Text>
                            Kart ödənişi:{' '}
                            <ReactPdf.Text style={{ color: 'green' }}>
                                {data?.reduce((acc, val) => {
                                    return acc + val?.payment_card
                                }, 0).toFixed(2)}{' '}AZN
                            </ReactPdf.Text>
                        </ReactPdf.Text>
                    )}
                </ReactPdf.View>
                <ReactPdf.View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginTop: 5,
                        fontSize: 10
                    }}>
                    {data?.length && (
                        <ReactPdf.Text>
                            Cəm borc:{' '}
                            <ReactPdf.Text style={{ color: 'red' }}>
                                {data?.reduce((acc, val) => {
                                    return acc + val?.debt
                                }, 0).toFixed(2)}{' '}AZN
                            </ReactPdf.Text>
                        </ReactPdf.Text>
                    )}
                </ReactPdf.View>
            </ReactPdf.Page>
        </ReactPdf.Document>}>
            {({ url }) => {
                return <Can action="examinationPayment_pdf">
                    <a className={`btn btn-primary ${!item && !selectedExaminations?.length && 'disabled'}`}
                        href={url}
                        target="_blank">
                        <i className="bx bx-printer" />
                    </a>
                </Can>;
            }}
        </ReactPdf.BlobProvider>
    )
}

export default PDFTemplate
