import { Controller, useForm } from "react-hook-form";
import { Button, Col, Input, Label, ModalBody, ModalFooter, Row, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import FormHelper from "../../helpers/form";
import Api from "api/deposit";      
import Patients from "../../api/patients"; 

import Select from "react-select";

const Add = ({ fetchData, form, setForm }) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [patients, setPatients] = useState([]);
  const data = form?.data; 

  const submit = async (values) => {
    setLoader(true);
    try {
        const formData = new FormData();
        formData.append("patient_id", values.patient_id?.value);
        formData.append("description", values.description || "");
        formData.append("price_deposit", values.price_deposit);

        if (data?.id) {
            await Api.update(formData, data.id);
        } else {
            await Api.add(formData);
        }

        fetchData();
        setForm({});
    } catch (e) {
        FormHelper.setApiErrors(e?.response?.data, setError);
    } finally {
        setLoader(false);
    }
};


  const fetchPatients = async () => {
    const { data } = await Patients.getSelect();
    setPatients(data);
  };

  useEffect(() => {
    fetchPatients();
  }, []);


  useEffect(() => {
    if (data && patients?.length) {
        setValue('patient_id', {
            value: data?.patient_id,
            label: data?.patient,
        })
        setValue('price_deposit', data?.price_deposit)
        setValue('description', data.description)
    }
}, [form, patients])

  
  return (
    <form onSubmit={handleSubmit(submit)}>
      <ModalBody>
        <Row>
          <Col sm={12}>
            <div className="mb-3">
              <Label for="patient_id">Pasient</Label>
              <Controller
                name="patient_id"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={patients}
                    placeholder="Pasient seçin"
                    className={`w-100 ${errors?.patient_id ? "is-invalid" : ""}`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {FormHelper.generateFormFeedback(errors, "patient_id")}
            </div>

            <div className="mb-3">
              <Label for="price_deposit">Məbləğ</Label>
              <Controller
                name="price_deposit"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    id="price_deposit"
                    placeholder="Məbləğ"
                    className={`w-100 ${errors?.price_deposit ? "is-invalid" : ""}`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {FormHelper.generateFormFeedback(errors, "price_deposit")}
            </div>
            <div className="mb-3">
              <Label for="description">Qeyd</Label>
              <Controller
                name="description"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="text"
                    id="description"
                    placeholder="Qeyd"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>


          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          type="button"
          onClick={() => setForm({})}
          color="secondary"
        >
          Bağla
        </Button>
        <Button
          disabled={loader}
          type="submit"
          color="primary"
        >
          {loader ? <Spinner color="light" size="sm" /> : "Yadda saxla"}
        </Button>
      </ModalFooter>
    </form>
  );
};

export default Add;
